import styles from './styles';

function Button({ text, onItemPress, primary, cancel, className }) {
  
  if (cancel) {
    return (
      <div className={`${styles.buttonContainer} ${styles.cancel} ${className}`} onClick={onItemPress}>
        <strong>{text}</strong>
      </div >
    )
  }
  return (
    <div className={primary ? `${styles.buttonContainer} ${styles.primary} ${className}` : `${styles.buttonContainer} ${styles.secondary} ${className}`} onClick={onItemPress}>
      <strong>{text}</strong>
    </div >
  );
}

export default Button
