import styles from './styles';
import React, { useState, useEffect } from 'react'
import Container from '../../components/Container';
import Button from '../../components/Button';
import Text from '../../components/Text';
import { SUPPORT_EMAIL, DEMO_URL, APP_URL, MAIL_TO_SUPPORT_URL } from '../../settings';


function Nav({ scheduleDemo, getStarted, login, showButtons, includeSubTitle }) {

    const home = () => {
        window.location.href = '/'
    }

    const returnLeftSection = () => {
        return (
            <Container className={styles.section}>
                <Container className={styles.clickContainer} onClick={home}>
                    <Text className={styles.titleText} text={'Get up to $50 in early pay right now* →'} />
                    {true && (
                        <Text className={styles.subTitleText} text={'* Conditional on application approval'} />
                    )}
                </Container>
            </Container>
        )
    }

    const returnTryForFreeCTA = () => {
        return (
            <Button text={'Get Started'} onItemPress={getStarted} primary={true} className={styles.btn} />
        )
    }

    const returnLoginCTA = () => {
        return (
            <Button text={'Sign In'} onItemPress={login} className={styles.secondaryBtn} />
        )
    }

    const quickstart = () => {
        window.location.href = './quickstart'
    }


    const returnScheduleDemoCTA = () => {
        return (
            <Button text={'Schedule Demo'} onItemPress={scheduleDemo} className={styles.secondaryBtn} />
        )
    }

    const returnShowQuickstartCTA = () => {
        return (
            <Button text={'Quickstart'} onItemPress={quickstart} className={styles.secondaryBtn} />
        )
    }

    const handleBackgroundClick = () => {
        window.location.href = APP_URL
    };

    const returnRightSection = () => {

        return (
            <Container className={styles.section}>
                <Container className={styles.menuItems}>
                    {showButtons && (
                        <React.Fragment>
                            {returnLoginCTA()}
                            {returnScheduleDemoCTA()}
                            {returnShowQuickstartCTA()}
                            {returnTryForFreeCTA()}
                        </React.Fragment>
                    )}
                </Container>
            </Container>
        )
    }

    return (
        <Container className={styles.background} onClick={handleBackgroundClick}>
            <Container className={styles.innerPanel}>
                {returnLeftSection()}
                {/* {returnRightSection()} */}
            </Container>
        </Container>
    );
}

export default Nav;
