import {colors} from '../../../../Styles'
import { css } from 'emotion'

const background = css`
  height: 290px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primary};
`

const btn = css`
  width: 135px;
  height: 43px;
  border-radius: 100px;
  background-color: white;
  color: black;
`

const innerPanel = css`
  // height: 100px;
  width: 1200px;
  display: flex;
  flex-direction: row;
  margin-top: 50px;

  // background-color: yellow;
`

const section = css`
  width: 600px;
  // height: 100px;

  // background-color: blue;

`

const footerTitle= css`
  width: 400px;
  margin-bottom: 10px;
  color: white;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.218182px;
  font-weight: bold;

`

const footerCaption = css`
  width: 400px;
  font-size: 12px;
  opacity: 0.8;
  line-height: 18px;
  color: white;

`

const spacer = css`
  width: 100%;
  height: 1px;
  background-color: white;
  margin-top: 53.5px;
  opacity: 0.4;

`

const upperContainer = css`


`

const menuItems = css`
  width: 100%;
  display: flex;
  justify-content: flex-end;

`

const styles = {
  background,
  btn,
  spacer,
  section,
  innerPanel,
  upperContainer,
  menuItems,
  footerCaption,
  footerTitle,
}

export default styles




