import styles from './styles';
import React, { useState, useEffect } from 'react'
import Container from '../../../../components/Container';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';

function Steps({ scheduleDemo, getStarted }) {
    

    const returnLeftSection = () => {
        return (



            <Container className={styles.section}>
                <Text className={styles.summaryText} text={'Testimonials'} />
                <Text className={styles.mainText} text={'⭐⭐⭐⭐⭐'} />
                <Text className={styles.captionText} text={'"Stream is super helpful in times of need! Plus it\'s simple and fast to request!!"'} />

                {returnTryForFreeCTA()}
            </Container>
        )
    }

    // TODO write a general component in components for this
    const returnTryForFreeCTA = () => {
        return (
            <Button text={'Learn More'} onItemPress={getStarted} primary={true} className={styles.btn} />
        )
    }

    const returnRightSection = () => {
        return (
            <Container className={styles.section}>
                <Container className={styles.firstImage}>
                </Container>
            </Container>
        )
    }

    return (
        <Container className={styles.background}>
            <Container className={styles.innerPanel}>
                {returnLeftSection()}
                {returnRightSection()}
            </Container>

        </Container>
    );
}

export default Steps;
