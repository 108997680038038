import {colors,fonts} from './Styles'
import { css } from 'emotion'
// import Hero from './Assets/hero_image.png'
import Arrow from './Assets/arrow.svg'

const detailsDescription = css`
  margin-top: 50px;
  color: ${colors.textLight};
  width: 30%;
  font: ${colors.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
  /* or 25px */
  letter-spacing: 1px;
  position: absolute;
  width: 473px;
  height: 75px;
  left: 100px;
  top: 492px;
`

const link = css`
  color: ${colors.link};
  &:hover {
    color: ${colors.linkHover};
  }
`

const termsLink = css`
  margin-top: 30px;
  color: ${colors.link};
  font-size: 15px;
  &:hover {
    color: ${colors.linkHover};
  }
`

const feedbackLink = css`
  margin-top: 10px;
  color: ${colors.link};
  font-size: 15px;
  &:hover {
    color: ${colors.linkHover};
  }
`

const headerImage = css`
  font-size: 46px;
  margin-top: 110px;
`

const leftPanel = css`
  margin-left: 100px;
`

const titleText = css`
  color: white;
  font: ${colors.primary};
  font-style: normal;
  font-weight: 650;
  font-size: 56px;
  line-height: 120%;
  position: absolute;
  width: 511px;
  height: 201px;
  left: 100px;
  top: 259px;
  left: 100px;
  line-height: 120%;
`

const coloredTitleText = css`
  background: linear-gradient(90deg, #538EE7 0%, #5654E5 53.68%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const tradeBtn = css`
  position: absolute;
  width: 128px;
  height: 41px;
  left: 100px;
  top: 612px;
`

const directionsTextContainer = css`
  position: absolute;
  width: 109px;
  height: 20px;
  left: 252px;
  top: 611px;
  font: ${fonts.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  cursor: pointer;
  /* identical to box height, or 20px */
  display: flex;
  align-items: flex-end;
  letter-spacing: 1px;
  color: #FFFFFF;
  margin-top: 13px;
  pointer-events: none;
`

const directionsArrowContainer = css`
  position: absolute;
  width: 6px;
  height: 10px;
  left: 362.5px;
  pointer-events: none;
  cursor: pointer;
  top: 622px;
  box-sizing: border-box;
  margin-top: 9px;
  background-image: url(${Arrow});
  background-repeat: no-repeat;
  background-size: cover;
`

const directionsContainer = css`
  cursor: pointer;
  height: 50px;
  width: 130px;
  position: absolute;
  left: 252px;
  top: 611px;
`

// const imagePanel = css`
//   position: absolute;
//   width: 1037px;
//   height: 873px;
//   left: 560px;
//   top: 106px;
//   background-image: url(${Hero});
//   background-repeat: no-repeat;
//   background-size: cover;
// `

const styles = {
  directionsArrowContainer,
  titleText,
  directionsTextContainer,
  directionsContainer,
  detailsDescription,
  link,
  coloredTitleText,
  // imagePanel,
  headerImage,
  tradeBtn,
  feedbackLink,
  termsLink,
  leftPanel,
}

export default styles




