import styles from './styles';
import React, { useState, useEffect } from 'react'
import Container from '../../../../components/Container';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';

function Features({ scheduleDemo, getStarted }) {

    const FEATURES_EXPLAINED = [
        {
            title: 'Market Your Drop',
            caption: 'Spread the word about your drop instead of being stuck trying to launch.'
        },
        {
            title: 'Focus On Designing',
            caption: 'Focus on designing your drop, not writing smart contracts.'
        },
        {
            title: 'Reduce Gas Prices',
            caption: 'Use efficient, battle tested smart contracts to launch your NFTs efficiently.'
        },
        {
            title: 'Get Alerts',
            caption: 'Stay notified of network status and transaction success rates as your drop unfolds.'
        },
        {
            title: 'Expert Help',
            caption: 'Get expert help when choosing a blockchain network for your drop.'
        },
    ]

    const returnFeatures = () => {
        return (
            <Container className={styles.stepsList}>
                <Container className={styles.rowContainer}>
                    {FEATURES_EXPLAINED.slice(0, 3).map((step, index) => (
                        returnFeature(step, index)

                    ))}
                </Container>
                <Container className={styles.rowContainer}>
                    <Container className={styles.lowerRowContainer}>
                        {FEATURES_EXPLAINED.slice(3, 5).map((step, index) => (
                            returnFeature(step, index + 3)

                        ))}
                    </Container>
                </Container>
            </Container>
        )
    }

    const returnFeature = (step, index) => {
        let imageKey = 'Image' + index.toString()
        console.log('image key: ', imageKey)
        return (
            <Container className={styles.itemContainer} key={index}>
                {/* This code (and styling) should be condensed in the future: */}
                {index === 0 && (
                    <Container className={styles.image1Container} >
                    </Container >
                )}
                {index === 1 && (
                    <Container className={styles.image2Container} >
                    </Container >
                )}
                {index === 2 && (
                    <Container className={styles.image3Container} >
                    </Container >
                )}
                {index === 3 && (
                    <Container className={styles.image4Container} >
                    </Container >
                )}
                {index === 4 && (
                    <Container className={styles.image5Container} >
                    </Container >
                )}


                <Text className={styles.featureTitleText} text={step.title} />
                <Text className={styles.featureCaptionText} text={step.caption} />
            </Container >
        )
    }

    return (
        <Container className={styles.background}>
            <Container className={styles.innerPanel}>
                <Text className={styles.summaryText} text={'WHY NFTDropper?'} />
                <Text className={styles.mainText} text={'Launch Effortlessly'} />
                {returnFeatures()}
            </Container>

        </Container>
    );
}

export default Features;
