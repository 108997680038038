import {colors} from '../../Styles'
import { css } from 'emotion'

const background = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primary};
  cursor: pointer;
`

const btn = css`
  height: 43px;
  border-radius: 100px;
`

const secondaryBtn = css`
  height: 43px;
  border-radius: 100px;
  margin-right: 15px;
`

const innerPanel = css`
  width: 1200px;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`

const section = css`
  width: 600px;
`

const titleText= css`
  width: 500px;
  letter-spacing: -0.218182px;
  font-weight: bold;
  font-size: 14px;
  // line-height: 30px;
  letter-spacing: -0.218182px;
  // margin-top: 5px;
  color: white;
  // color: ${colors.primary};
  opacity: .85;
  // margin-bottom: 20px;
`

const menuItems = css`
  width: 100%;
  display: flex;
  justify-content: flex-end;

`

const clickContainer= css`
  cursor: pointer;

`

const subTitleText= css`
  color: white;
  margin-top: 5px;
  font-size: 10px;
  opacity: .45;
  margin-bottom: 10px;

`


const styles = {
  background,
  btn,
  section,
  clickContainer,
  innerPanel,
  menuItems,
  secondaryBtn,
  subTitleText,
  titleText
}

export default styles




