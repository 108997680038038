import React from 'react'
import styles from './styles'

const Container = ({
  children, hostRef, ...props
}) => {

  return (
    <div className={styles.container} ref={hostRef || null} {...props}>
      {children}
    </div>
  )
}

export default Container


