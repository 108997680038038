// import {colors} from '../../Styles'
import { css } from 'emotion'
import Alerts from './Images/Alerts.png'
import Gas from './Images/Gas.png'
import Build from './Images/Build.png'
import Notifications from './Images/Notifications.png'
import Graph from './Images/Graph.png'

const background = css`
  height: 800px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const innerPanel = css`
  width: 1200px;
`

const summaryText = css`
  color: #2A91F0;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-top: 60px;
`

const section = css`
  height: 500px;
  width: 600px;

`

const btn = css`
  width: 135px;
  height: 43px;
  border-radius: 100px;
`

const detailText = css`
  width: 477px;
  height: 89px;
  font-size: 16px;
  line-height: 30px;
  /* or 187% */
  color: rgba(17, 17, 17, 0.8);
  margin-bottom: 29px;
`

const mainText = css`
  width: 414px;
  height: 78px;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.04em;
  margin-top: 20px;
  width: 100%;
  text-align: center;

`

const firstImage = css`
  width: 540px;
  height: 456px;
  border-radius: 24px;
  background-repeat: no-repeat;
  background-size: cover;

  
`

const featureTitleText = css`
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  text-align: center;
  letter-spacing: -0.04em;
  width: 100%;
  text-align: center;
  margin-top: 16px;
`

const featureCaptionText = css`
  width: 340px;
  font-size: 15px;
  line-height: 30px;
  /* or 200% */
  text-align: center;
  color: rgba(17, 17, 17, 0.8);
  margin-top: 16px;
`

const itemContainer = css`
  width: 340px;
`

const rowContainer = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const lowerRowContainer = css`
  width: 64.5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 60px;
`

const image1Container = css`
  height: 40px;
  width: 40px;
  background: rgba(6, 56, 102, 0.1);
  border-radius: 100px;
  margin: auto;
  border: 20px solid rgba(6, 56, 102, 0.1);;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${Graph});
`

const image2Container = css`
  height: 40px;
  width: 40px;
  background: rgba(6, 56, 102, 0.1);
  border-radius: 100px;
  margin: auto;
  border: 20px solid rgba(6, 56, 102, 0.1);;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${Build});
`

const image3Container = css`
  height: 40px;
  width: 40px;
  background: rgba(6, 56, 102, 0.1);
  border-radius: 100px;
  margin: auto;
  border: 20px solid rgba(6, 56, 102, 0.1);;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${Gas});
`

const image4Container = css`
  height: 40px;
  width: 40px;
  background: rgba(6, 56, 102, 0.1);
  border-radius: 100px;
  margin: auto;
  border: 20px solid rgba(6, 56, 102, 0.1);;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${Alerts});
`


const image5Container = css`
  height: 40px;
  width: 40px;
  background: rgba(6, 56, 102, 0.1);
  border-radius: 100px;
  margin: auto;
  border: 20px solid rgba(6, 56, 102, 0.1);;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${Notifications});
`



const styles = {
  background,
  btn,
  rowContainer,
  mainText,
  featureTitleText,
  section,
  innerPanel,
  summaryText,
  featureCaptionText,
  detailText,
  firstImage,
  itemContainer,
  lowerRowContainer,

  image1Container,
  image2Container,
  image3Container,
  image4Container,
  image5Container,


}

export default styles




