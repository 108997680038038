
import React from 'react';
import styles from './styles'

const ReturnPage = () => {

  const handleReactNativeMessage = (message) => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(message);
    }
  }

  
  React.useEffect(() => {
    handleReactNativeMessage('Returning to the app...');
  }, []);

  return (
    <div>
      {/* <img src={logo} alt="Logo" className="logo" /> */}
      <h1>Redirecting you back to the app...</h1>
    </div>
  );

}

export default ReturnPage
