// Env dependent settings and constants are contained in this file.

const env = process.env.REACT_APP_NODE_ENV ? process.env.REACT_APP_NODE_ENV : 'staging'

console.log('Web app environment: ', env)

// Initializations:
let SERVER_BASE = ''

// Assignments:
const DOMAIN = 'getstreamapp.com'
const VERSION_NUMBER = '1.0'
const SUPPORT_EMAIL = 'support@' + DOMAIN
// if (env === 'staging' || env === 'development') {
//     SERVER_BASE = 'https://stream-rest-staging.herokuapp.com/'
// } else if (env === 'production') {
//     SERVER_BASE = 'https://stream-rest-production.herokuapp.com/'
// }
console.log("using env: ", env)
SERVER_BASE = 'https://stream-rest-production.herokuapp.com/' // TODO use env
const MAIL_TO_SUPPORT_URL = 'mailto:' + SUPPORT_EMAIL + '?subject=Support%20Request'
const APP_URL = 'https://apps.apple.com/us/app/stream-get-paid-early/id6446709359'

export { MAIL_TO_SUPPORT_URL, SUPPORT_EMAIL, APP_URL, DOMAIN, VERSION_NUMBER, SERVER_BASE}