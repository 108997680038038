import React from 'react';
import ReactDOM from 'react-dom/client';  // Use the 'client' from react-dom
import './index.css';
import { Router } from '@reach/router';
import App from './App';
import Support from './Screens/Support';
import Terms from './Screens/Terms';
import Privacy from './Screens/Privacy';
import ReturnPage from './Screens/ReturnPage';
import ForgotPassword from './Screens/ForgotPassword';

// Create the root using ReactDOM.createRoot
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the app inside the root
root.render(
  <Router>
    <App default />
    <ReturnPage path="/verify/return" />
    <Support path="/support" />
    <Terms path="/legal/terms" />
    <Privacy path="/legal/privacy" />
    <ForgotPassword path="/reset" />
  </Router>
);

// Optionally, you can still measure performance
// reportWebVitals(); 
