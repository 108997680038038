import {colors,fonts} from '../../Styles'
import { css } from 'emotion'

const buttonContainer = css`
  cursor: pointer;
  display: flex;
  border-radius: 2px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font: ${fonts.primary}
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  height: 48px;
  border-radius: 100px;
  padding-left: 15px;
  padding-right: 15px;

`

const primary = css`
  border: 1px solid ${colors.primary};
  background-color: ${colors.primary};
  color: white;
  &:hover {
    background-color: ${colors.primary};
    border: 1px solid ${colors.primary};
  }
`

const secondary = css`
  border: 1px solid ${colors.primary};
  color: ${colors.primary};
  &:hover {
    border: 1px solid ${colors.primary};
    color: ${colors.primary};
  }
`

const cancel = css`
  border: 1px solid ${colors.warning};
  color: ${colors.warning};
  &:hover {
    border: 1px solid ${colors.warningHover};
    color: ${colors.warningHover};
  }
`


const styles = {
  buttonContainer,
  cancel,
  secondary,
  primary
}

export default styles



