import {colors} from '../../../../Styles'
import { css } from 'emotion'
import Image1 from './Images/1.png'
import Image2 from './Images/2.png'
import Image3 from './Images/3.png'
import Writing from './Images/link.png'

const background = css`
  height: 700px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(42, 145, 240, 0.05);
`

const innerPanel = css`
  display: flex;

`

const summaryText = css`
  color: ${colors.primary};
  font-weight: bold;
`

const section = css`
  height: 500px;
  width: 600px;

`

const btn = css`
  width: 135px;
  height: 43px;
  border-radius: 100px;
`

const detailText = css`
  width: 477px;
  height: 89px;
  font-size: 16px;
  line-height: 30px;
  /* or 187% */
  color: rgba(17, 17, 17, 0.8);
  margin-bottom: 29px;
  font-family: 'Inter', sans-serif;  /* Updated font to Inter */

`

const mainText = css`
  width: 414px;
  height: 78px;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.04em;
  margin-top: 20px;
  margin-bottom: 40px;
  font-family: 'Inter', sans-serif;  /* Updated font to Inter */
`

const firstImage = css`
  width: 540px;
  height: 456px;
  border-radius: 24px;
  background-image: url(${Writing});
  background-repeat: no-repeat;
  background-size: cover;
  
  /* Initial state: No shadow, no scale */
  transition: all 0.3s ease-in-out; /* Smooth transition for all effects */

  /* Hover state: Shadow and scale */
  &:hover {
    transform: scale(1.05); /* Slight zoom effect */
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); /* Shadow effect */
  }
`

const stepsList = css`
  margin-bottom: 40px;  
  // background-color: red;
`

const stepTitleText = css`
  width: 410px;
  height: 19px;
  font-weight: bold;
  font-size: 16px;
  color: #111111;
  margin-bottom: 4px;
  font-family: 'Inter', sans-serif;  /* Updated font to Inter */

`

const stepCaptionText = css`
  width: 410px;
  // height: 30px;
  font-size: 16px;
  line-height: 25px;
  color: rgba(17, 17, 17, 0.8);
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;  /* Updated font to Inter */

`

const styles = {
  background,
  btn,
  mainText,
  stepsList,
  section,
  innerPanel,
  summaryText,
  detailText,
  firstImage,
  stepTitleText,
  stepCaptionText,
}

export default styles




