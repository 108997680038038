import baseAPI from './baseAPI'
import { SERVER_BASE } from '../settings.js';

const { get, post } = baseAPI

const API = {
  async reset(payload) {
    const endpoint = SERVER_BASE + 'user/reset/accept'
    const result = await post(endpoint, payload)
    return result
  },
}

export default API
