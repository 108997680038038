import Container from '../../components/Container';
import React, { useState, useEffect } from 'react'
import Text from '../../components/Text';
import TextField from '@material-ui/core/TextField';
import Nav from '../../components/Nav';
import styles from './styles'

const sections = [
    {text: "At StreamFunds, Inc. (Stream), we are creating a financial product that is meant to be fair and accessible. This Privacy Policy details how we collect and use your personal information."},
    {text: "This privacy policy details how Stream uses your information and does not include the privacy policies of any third party partners."},
    {text: "Stream collects your name and email during signup. This information is not shared with third party services except for Plaid Inc. and Pinwheel, where it is required in order to verify income and faciliate transfers. A recurring direct deposit is required via Pinwheel to use Stream."},
    {text: "With your consent, Stream also connects to Plaid and Pinwheel to faciliate sharing of your bank income information, as well as executing incoming and outgoing transfers."},
    {text: "\nStream also collects usage information to improve our services. This includes statistics like frequency of app opens, device identifiers, session durations, as well as information like time spent using certain features."},
    {text: "You may submit a request to delete your data by sending an email to support@getstreamapp.com. Stream may still continue to store any data in aggregated, anonymized form in compliance with CCPA."},
    {text: "If you have specific questions about this privacy policy, reach out to support@getstreamapp.com"},
]

function Privacy({ }) {

    const returnTextChunks = () => {
        return (
            <React.Fragment>
                {sections.map((section, index) => (
                    <Text className={styles.explanationText} text={section.text} />
                ))}
            </React.Fragment>
        )
    }

    const returnForm = () => {
        return (

            <React.Fragment>
                {/* <Nav includeSubTitle={true}/> */}
                <Text className={styles.headerText} text={'Privacy Policy'} />
                {returnTextChunks()}

            </ React.Fragment>
        )
    }

    return (
        <Container >
            {returnForm()}
        </Container>
    );
}

export default Privacy
