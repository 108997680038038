import { createMuiTheme, ThemeProvider } from "@material-ui/core";

// Contains colors, fonts and themes used throughout the app.

const colors = {
    primary:  '#5433FF',
    primaryDark: '#582FC4',
    primaryLight: '#b0aff3',
    text: 'white',
    textDark: '#003B00',
    textLight: 'rgba(255, 255, 255, 0.8)',
    link: '#538EE6',
    linkHover: '#5654E5',
    warning: '#FF4747',
    warningHover: '#cc0000',
    lightGray: '#D3D3D3',
    darkGray: 'grey',
    hoverBackground: '#F8F8F8',
    bodyBackground: 'black',
    bodyBackgroundAlt: '#121116',
    tableCellAlt: '#16151C',
    tableCellHover: '#29282f',
    banner: 'white',
    warningBanner: '#ff8c69',
}

const fonts = {
    primary: 'Avenir',
    logoFont: 'Source Sans Pro'
}

const theme = createMuiTheme({ // Material UI theme.
    palette: {
        primary: {
            main: colors.primary
        },
    },
});

export {colors, fonts, theme}