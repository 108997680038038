import {colors} from '../../Styles'
import { css } from 'emotion'

const container = css`
`

const styles = {
  container
}

export default styles




