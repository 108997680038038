// import {colors} from '../../Styles'
import { css } from 'emotion'
import main from './Images/main.png'
import Chart from './Images/Chart.png'

const background = css`
  height: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // background: rgba(42, 145, 240, 0.05);
`

const innerPanel = css`
  display: flex;

`

const summaryText = css`
  color: #2A91F0;
  font-weight: bold;
  margin-top: 106px;
  font-family: 'Inter', sans-serif;  /* Updated font to Inter */

`

const section = css`
  height: 500px;
  width: 600px;

`

const btn = css`
  width: 135px;
  height: 43px;
  border-radius: 100px;
`

const detailText = css`
  width: 477px;
  height: 89px;
  font-size: 16px;
  line-height: 30px;
  /* or 187% */
  color: rgba(17, 17, 17, 0.8);
  margin-bottom: 29px;
  font-family: 'Inter', sans-serif;  /* Updated font to Inter */

`

const mainText = css`
  width: 414px;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.04em;
  margin-top: 30px;
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;  /* Updated font to Inter */

`

const firstImage = css`
  width: 300px;
  height: 285px;
  border-radius: 24px;
  background-image: url(${main});
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 80px;


  border: 1px solid #2991F0;
  border-radius: 30px;
  
  /* Initial state: No shadow, no scale */
  transition: all 0.3s ease-in-out; /* Smooth transition for all effects */

  /* Hover state: Shadow and scale */
  &:hover {
    transform: scale(1.05); /* Slight zoom effect */
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); /* Shadow effect */
  }
`

const captionText = css`
  width: 392px;
  font-size: 16px;
  line-height: 30px;
  /* or 187% */
  color: rgba(17, 17, 17, 0.8);
  margin-bottom: 29px;
  font-family: 'Inter', sans-serif;  /* Updated font to Inter */

`

const styles = {
  background,
  btn,
  captionText,
  mainText,
  section,
  innerPanel,
  summaryText,
  detailText,
  firstImage,
}

export default styles




