import axios from 'axios'
import qs from 'qs'
import { SERVER_BASE } from '../settings';

class BaseAPI {
  constructor() {
    this.axios = axios.create({
      baseURL: SERVER_BASE
    })
  }

  get = async (url, params) => {
    let jwt = await localStorage.getItem('jwt')
    const response = await this.axios({
      method: 'get',
      url,
      params,
      headers: {Authorization: jwt}
    })
    return response
  }

  post = async (url, data) => {
    let jwt = await localStorage.getItem('jwt')
    console.log('base api url: ', url)
    console.log('base api hit', data)
    const response = await this.axios({
      method: 'post',
      url,
      data: qs.stringify(data),
      headers: {Authorization: jwt}
    })
    return response
  }
}

const baseAPI = new BaseAPI()

export default baseAPI
