import { colors } from '../../../../Styles'
import { css } from 'emotion'
import Swath from './Images/Swath.png'
import Logo from './Images/logo.png'
import HeroImage from './Images/hero.png'

const background = css`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #4A90E2, #50E3C2, #9013FE);
  background-size: 400% 400%;
  animation: gradientBackground 12s ease infinite;

  @keyframes gradientBackground {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`

const innerPanel = css`
  display: flex;
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
`

const section = css`
  height: 500px;
  width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const btn = css`
  width: 160px;
  height: 43px;
  border-radius: 100px;
  margin-right: 10px;
`

const detailText = css`
  font-family: 'Inter', sans-serif;  /* Updated font to Inter */
  width: 477px;
  font-size: 18px;
  line-height: 30px;
  color: rgba(17, 17, 17, 0.8);
  margin-bottom: 29px;
  margin-top: 50px;
`

const heroText = css`
  font-family: 'Inter', sans-serif;  /* Updated font to Inter */
  font-weight: 700;
  font-size: 64px;
  line-height: 75px;
  letter-spacing: -0.02em;
  color: #111;  /* Darker text for better contrast */
  margin-bottom: 12px;
  opacity: 0.85;
`

const firstImage = css`
  width: 270px;
  height: 270px;
  padding: 30px;
  border-radius: 24px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 30px;
  border: 1px solid #2991F0;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }

  opacity: 0.75;
`;

const mobileImage = css`
  background-image: url(${Logo});
`;

const desktopImage = css`
background-image: url(${HeroImage});
`;

const swathImage = css`
  width: 300px;
  height: 6px;
  position: absolute;
  margin-top: 6px;
  border-radius: 24px;
  background-color: white;
  opacity: .5;
  // background-image: url(${Swath});
  // background-repeat: no-repeat;
  // background-size: cover;
  background-position: center;
  mix-blend-mode: screen; /* Ensures that the swath image blends with the background */
`

const buttonsContainer = css`
  display: flex;
  flex-direction: row;
`

const styles = {
  background,
  btn,
  heroText,
  section,
  swathImage,
  innerPanel,
  buttonsContainer,
  detailText,
  firstImage,
  mobileImage,
  desktopImage
}

export default styles
