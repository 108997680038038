import styles from './styles';
import React, { useState, useEffect } from 'react'
import Container from '../../../../components/Container';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';

function Focus({ scheduleDemo, getStarted }) {

    const quickstart = () => {
        window.location.href='./quickstart'
    }

    const returnLeftSection = () => {
        return (

            <Container className={styles.section}>
                <Container className={styles.firstImage}>
                </Container>
                <Container className={styles.secondImage}>
                </Container>
                <Container className={styles.thirdImage}>
                </Container>
                <Container className={styles.fourthImage}>
                </Container>
            </Container>
        )
    }

    // TODO write a general component in components for this
    const returnTryForFreeCTA = () => {
        return (
            <Button text={'Quickstart Guide'} onItemPress={quickstart}  className={styles.btn} />
        )
    }

    const returnRightSection = () => {
        return (
            <Container className={styles.section}>
                <Text className={styles.summaryText} text={'SAVE MONEY'} />
                <Text className={styles.mainText} text={'Access More Overtime'} />
                <Text className={styles.detailText} text={'Focus on launching your NFT drop and leave the mundane tasks to us. From image uploading to smart contract deployment, NFTDropper makes launching a breeze.'} />

                {returnTryForFreeCTA()}
            </Container>
        )
    }

    return (
        <Container className={styles.background}>
            <Container className={styles.innerPanel}>
                {returnLeftSection()}
                {returnRightSection()}
            </Container>

        </Container>
    );
}

export default Focus;
