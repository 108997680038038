import Container from '../../components/Container';
import React, { useState, useEffect } from 'react'
import Text from '../../components/Text';
import TextField from '@material-ui/core/TextField';
import Nav from '../../components/Nav';
import styles from './styles'

function Support({ }) {

    const text = "We try our best to respond to all support requests within 24 hours. To get help with your account, send an email to support@getstreamapp.com"

    const returnTextChunks = () => {
        let chunks = text.split('\n')
        return (
            <React.Fragment>
                {chunks.map((text, index) => (
                    <Text className={styles.explanationText} text={text} />
                ))}
            </React.Fragment>
        )
    }

    const returnForm = () => {
        return (

            <React.Fragment>
                {/* <Nav includeSubTitle={true}/> */}
                <Text className={styles.headerText} text={'Get Help'} />
                {/* <Text className={styles.directionsText} text={returnDirections()} /> */}
                {/* <Text className={styles.explanationText} text={article1} /> */}
                {returnTextChunks()}

            </ React.Fragment>
        )
    }

    return (
        <Container >
            {returnForm()}
        </Container>
    );
}

export default Support
