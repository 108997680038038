// import {colors} from '../../Styles'
import { css } from 'emotion'
import Image1 from './Images/1.png'
import Image2 from './Images/2.png'
import Image3 from './Images/3.png'
import Image4 from './Images/4.png'

const background = css`
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const innerPanel = css`
  display: flex;
  margin-top: 155px;

`

const summaryText = css`
  color: #2A91F0;
  font-weight: bold;
  margin-top: 30px;
`

const section = css`
  height: 500px;
  width: 600px;
`

const btn = css`
  width: 135px;
  height: 43px;
  border-radius: 100px;
`

const detailText = css`
  width: 477px;
  height: 89px;
  font-size: 16px;
  line-height: 30px;
  /* or 187% */
  color: rgba(17, 17, 17, 0.8);
  margin-bottom: 29px;
`

const mainText = css`
  width: 414px;
  // height: 78px;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  letter-spacing: -0.04em;
  margin-top: 30px;
`

const firstImage = css`
  width: 262px;
  height: 176px;
  border-radius: 24px;
  background-image: url(${Image1});
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 50px;
  margin-left: 20px;
`

const secondImage = css`
  width: 211px;
  height: 196px;
  border-radius: 24px;
  position: absolute;
  margin-top: -200px;
  margin-left: 217px;
  background-image: url(${Image2});
  background-repeat: no-repeat;
  background-size: cover;
`

const thirdImage = css`
  width: 210px;
  height: 110px;
  border-radius: 24px;
  margin-left: 70px;
  margin-top: -38px;
  background-image: url(${Image3});
  background-repeat: no-repeat;
  background-size: cover;
`

const fourthImage = css`
  width: 133px;
  height: 110px;
  border-radius: 24px;
  margin-left: 225px;
  margin-top: -130px;
  background-image: url(${Image4});
  background-repeat: no-repeat;
  background-size: cover;
`

const stepsList = css`
  margin-bottom: 40px;  
  // background-color: red;
`

const stepTitleText = css`
  width: 410px;
  height: 19px;
  font-weight: bold;
  font-size: 16px;
  color: #111111;
  margin-bottom: 4px;
`

const stepCaptionText = css`
  width: 410px;
  // height: 30px;
  font-size: 16px;
  line-height: 25px;
  color: rgba(17, 17, 17, 0.8);
  margin-bottom: 20px;
`

const styles = {
  background,
  btn,
  mainText,
  stepsList,
  section,
  innerPanel,
  summaryText,
  detailText,
  firstImage,
  secondImage,
  thirdImage,
  fourthImage,
  stepTitleText,
  stepCaptionText,
}

export default styles




